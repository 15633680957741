<template>
    <div :is="layout">
      <div v-if="step === 1">
        <div style="position: absolute; top: 90px; left: 20px; z-index: 9">
          <v-btn @click="is_show_tubao = !is_show_tubao">{{is_show_tubao ? '隐藏' : '预测'}}飞行航迹</v-btn>
          <v-btn style="margin-left: 20px" @click="call_algorithm">冲突解决</v-btn>
        </div>
        <div id="cesiumContainer"></div>
      </div>
      <div v-if="step===0">
        <v-container>
          <v-card>
            <v-card-title>
              配置冲突检测模块参数
            </v-card-title>
            <v-container class="pl-12 pr-12">
              <v-row>
                <v-col cols="12">
                  <v-select label="选择算法"></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="飞机A经度"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="飞机A纬度"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="飞机B经度"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="飞机B纬度"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="submit_options">确认</v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </div>
    </div>
</template>
<script>
    let Cesium;
    const generateRandomNumber  = (min, max) => Math.floor((max - min) * Math.random() + min)
    const random_flight_number = [
        generateRandomNumber(1000, 9999),
        generateRandomNumber(1000, 9999),
    ]
    export default {
        name: 'cesium-test',
        data: () => ({
            step: 0,
            viewer: null,
            positionProperty: null,
            layout: null,
            start: null,
            property_arr: [],
            particleSystem_arr: [],
            entity_arr: [],
            distance: 999999,
            flag: false,
            fetch_convex_hull_points: [[], []],
            time_and_position: [{}, {}],
            current_conflict: [[],[]],
            aircraft_flight: [[], []], // 飞机的航线
            end_points: [], // 最后的一个点位
            is_show_tubao: false,
            jiaodu: [],
        }),
        methods: {
            submit_options() {
              this.step++
              this.$nextTick(() => {
                this.init()
              })
            },
            call_algorithm() {
                this.viewer.entities.removeAll()
                this.viewer.dataSources.removeAll()
                const start_time = Cesium.JulianDate.fromDate(new Date('2020-10-10 10:00'))
                const stop_time = Cesium.JulianDate.fromDate(new Date('2020-10-10 14:10'))


                this.viewer.clock.startTime = start_time
                this.viewer.clock.stopTime = stop_time
                this.viewer.clock.currentTime = Cesium.JulianDate.fromDate(new Date('2020-10-10 10:00'))
                this.viewer.timeline.zoomTo(start_time, stop_time);// 循环执行,即为2，到达终止时间，重新从起点时间开始
                this.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
                this.viewer.scene.globe.enableLighting = true;
                this.viewer.clock.multiplier = 10


                // 首先2条线的数据有了
                // 其次飞行冲突数据有了
                // 创建一个变量, 用于寸保存后的数据
                // 处理数据逻辑.
                // 循环飞机航路数据, 确定要修改某一条数据, 找出冲突的数据点, 替换成新的位置
                let new_flight_data = []

                for (let i of this.aircraft_flight[0]) {
                    let lat = i[0]
                    let lng = i[1]
                    for (let conflict of this.current_conflict[0]) {
                        const _lat = conflict._meta.lat
                        const _lng = conflict._meta.lng
                        if (lat === _lat && lng === _lng) {
                            const jiaodu = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(lng, lat), new google.maps.LatLng(this.end_points[0][1], this.end_points[0][0]))
                            const end_point = this.destinationVincenty({
                                lng: lat,
                                lat: lng
                            }, jiaodu + 90, 20408)
                            lat = end_point[0]
                            lng = end_point[1]
                        }
                    }

                    new_flight_data.push([lng, lat])
                }

                const property = new Cesium.SampledPositionProperty()
                // new_flight_data.reverse()



                const get_callback = (lng, lat, index, end_point_index, property, end_point) => {
                    const middle_index = Math.floor(52 / 2)
                    // tubao_entity.box.material = Cesium.Color.RED
                    return (time) => {
                        if (!this.is_show_tubao) {
                            return Cesium.Cartesian3(0, 0, 0)
                        }
                        const position = property.getValue(time)
                        if (!position) {
                            return new Cesium.Cartesian3(0, 0, 0)
                        }
                        const ellipsoid = this.viewer.scene.globe.ellipsoid;
                        const cartesian3 = new Cesium.Cartesian3(position.x, position.y, position.z);
                        const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
                        const _lat = Cesium.Math.toDegrees(cartographic.latitude);
                        const _lng = Cesium.Math.toDegrees(cartographic.longitude);

                        // 计算当前点 与终点的距离
                        const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat, lng), new google.maps.LatLng(end_point[0], end_point[1]))
                        // 判断飞机与终点的距离
                        const air_craft_distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(_lat, _lng), new google.maps.LatLng(end_point[0], end_point[1]))
                        // 如果飞机的距离 大于 当前点与终点的距离 显示
                        if (air_craft_distance > distance) {
                            if (26 > index) {
                                // 在中心点之前
                                const destance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat, lng), new google.maps.LatLng(_lat, _lng))
                                // let size = destance / 10
                                // let size = destance * index / 100
                                let max_size = index * 1000
                                let size = max_size  * (destance / 100000)
                                if (size > max_size) size = max_size
                                if (size < 100) size = 100;
                                size /= 3
                                return new Cesium.Cartesian3(size, size, size)
                            }else {
                                // 在中心点之后
                                const destance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat, lng), new google.maps.LatLng(_lat, _lng))
                                // let size = destance / 10 * (Math.abs(middle_index - index)  / 10)
                                let max_size = Math.abs(middle_index - Math.abs(middle_index - index)) * 1000;
                                let size = max_size * (destance / 100000);
                                if (size > max_size) size = max_size;
                                if (size < 100) size = 100;
                                size /= 3
                                return new Cesium.Cartesian3(size, size, size)

                            }
                        }

                        // 如果飞机的距离 小于 当前点与终点的距离 不显示
                        if (air_craft_distance < distance) {
                            return new Cesium.Cartesian3(0, 0, 0)
                        }
                    }
                }






                let index = 0
                let end_point_0 = null
                for (let i of new_flight_data) {
                    const current_time = Cesium.JulianDate.addSeconds(start_time, index * 30, new Cesium.JulianDate())
                    property.addSample(current_time, Cesium.Cartesian3.fromDegrees( i[1],i[0], 100000))
                    index++
                    end_point_0 = JSON.parse(JSON.stringify(i))
                }

                const orientation = new Cesium.VelocityOrientationProperty(property)
                this.viewer.entities.add({
                    position: property,
                    availability: new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
                        start: start_time,
                        stop: stop_time
                    })]),
                    orientation: orientation,
                    model: {
                        show: true,
                        uri: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb",
                        minimumPixelSize: 64, //最小的模型像素
                        maximumScale: 528, //最大的模型像素
                        runAnimations: true, //是否显示动画
                        clampAnimations: true, //是否保持最后一针的动画
                        color: this.Cesium.Color.WHITE, //颜色
                    },
                    billboard: new this.Cesium.BillboardGraphics({
                        show: true,
                        image: 'https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/cesium_info_bg.png',
                        scale: .27,
                        horizontalOrigin: 200,
                        verticalOrigin: -300
                    }),
                    label: {
                        show: true,
                        text: `CA${random_flight_number[0]}\n32000 -\n${Math.abs(this.jiaodu[0]).toFixed(2)}°  480kn`,
                        font: 'font-size: 12px',
                        pixelOffset: new this.Cesium.Cartesian3(100, 0, 0)

                    },
                    path: {
                        show: true,
                        leadTime: 9999,
                        trailTime: 9999,
                        width: 1
                    }
                })



                // 添加凸包
                const customDataSource = new Cesium.CustomDataSource('customDataSource')
                index = 0
                for (let i of new_flight_data) {
                    const tubao_entity = new Cesium.Entity({
                        position: Cesium.Cartesian3.fromDegrees(i[1], i[0], 100000),
                        orientation: orientation,
                        box: new Cesium.BoxGraphics({
                            // dimensions: new Cesium.CallbackProperty(get_callback(LngLatArr[i * 2], LngLatArr[i * 2 + 1], i), false),
                            dimensions: new Cesium.CallbackProperty(get_callback(i[1], i[0], index, 0, property, end_point_0), false),
                            // dimensions: new Cesium.Cartesian3(1000, 1000, 1000),
                            // material: new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(set_convex_hull_color_callback(index), false)),
                            material: Cesium.Color.WHITE.withAlpha(.5),

                            fill: true,
                        }),
                    })
                    index++
                    customDataSource.entities.add(tubao_entity)
                }





                index = 0
                // this.aircraft_flight[1].reverse()
                let end_point_1 = null
                const property1 = new Cesium.SampledPositionProperty()
                for (let i of this.aircraft_flight[1]) {
                    const current_time = Cesium.JulianDate.addSeconds(start_time, index * 30, new Cesium.JulianDate())
                    property1.addSample(current_time, Cesium.Cartesian3.fromDegrees( i[0],i[1], 100000))
                    index++
                    end_point_1 = [i[1], i[0]]
                }

                const orientation1 = new Cesium.VelocityOrientationProperty(property1)
                this.viewer.entities.add({
                    position: property1,
                    availability: new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
                        start: start_time,
                        stop: stop_time
                    })]),
                    orientation: orientation1,
                    model: {
                        show: true,
                        uri: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb",
                        minimumPixelSize: 64, //最小的模型像素
                        maximumScale: 528, //最大的模型像素
                        runAnimations: true, //是否显示动画
                        clampAnimations: true, //是否保持最后一针的动画
                        color: this.Cesium.Color.WHITE, //颜色
                    },
                    billboard: new this.Cesium.BillboardGraphics({
                        show: true,
                        image: 'https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/cesium_info_bg.png',
                        scale: .27,
                        horizontalOrigin: 200,
                        verticalOrigin: -300

                    }),
                    label: {
                        show: true,
                        text: `CA${random_flight_number[1]}\n32000 -\n${Math.abs(this.jiaodu[1]).toFixed(2)}°  480kn`,
                        font: 'font-size: 12px',
                        pixelOffset: new this.Cesium.Cartesian3(100, 0, 0)

                    },
                    path: {
                        show: true,
                        leadTime: 9999,
                        width: 1,
                        trailTime: 9999
                    }
                })


                // 添加凸包
                index = 0
                for (let i of this.aircraft_flight[1]) {
                    const tubao_entity = new Cesium.Entity({
                        position: Cesium.Cartesian3.fromDegrees(i[0], i[1], 100000),
                        orientation: orientation,
                        box: new Cesium.BoxGraphics({
                            // dimensions: new Cesium.CallbackProperty(get_callback(LngLatArr[i * 2], LngLatArr[i * 2 + 1], i), false),
                            dimensions: new Cesium.CallbackProperty(get_callback(i[0], i[1], index, 1, property1, end_point_1), false),
                            // dimensions: new Cesium.Cartesian3(1000, 1000, 1000),
                            // material: new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(set_convex_hull_color_callback(index), false)),
                            material: Cesium.Color.WHITE.withAlpha(.5),

                            fill: true,
                        }),
                    })
                    index++
                    customDataSource.entities.add(tubao_entity)
                }
                this.viewer.dataSources.add(customDataSource)


            },
            init() {
                this.layout = this.$route.meta.use_layout
                this.viewer = new Cesium.Viewer('cesiumContainer', {
                    terrainProvider: Cesium.createWorldTerrain(),
                });

                const start_time = Cesium.JulianDate.fromDate(new Date('2020-10-10 10:00'))
                const stop_time = Cesium.JulianDate.fromDate(new Date('2020-10-10 14:10'))

                this.viewer.clock.startTime = start_time
                this.viewer.clock.stopTime = stop_time
                this.viewer.clock.currentTime = Cesium.JulianDate.fromDate(new Date('2020-10-10 10:00'))
                this.viewer.timeline.zoomTo(start_time, stop_time);// 循环执行,即为2，到达终止时间，重新从起点时间开始
                this.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;
                this.viewer.scene.globe.enableLighting = true;
                this.viewer.clock.multiplier = 10

                this.viewer.clock.onTick.addEventListener((clock) => {
                    if (this.property_arr.length) {
                        const ellipsoid = this.viewer.scene.globe.ellipsoid;
                        const air_1 = this.property_arr[0].getValue(clock.currentTime)

                        const air_2 = this.property_arr[1].getValue(clock.currentTime)
                        if (!air_1 || !air_2) return;
                        const cartographic1 = ellipsoid.cartesianToCartographic(air_1);
                        const lat_1 = Cesium.Math.toDegrees(cartographic1.latitude);
                        const lng_1 = Cesium.Math.toDegrees(cartographic1.longitude);
                        const cartographic2 = ellipsoid.cartesianToCartographic(air_2);
                        const lat_2 = Cesium.Math.toDegrees(cartographic2.latitude);
                        const lng_2 = Cesium.Math.toDegrees(cartographic2.longitude);
                        this.distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat_1, lng_1), new google.maps.LatLng(lat_2, lng_2))
                        // 判断距离是否小于指定的距离.小于指定的距离将展示飞机失事效果
                    }
                });


                const customDataSource = new Cesium.CustomDataSource('customDataSource')
                const entities = new Cesium.Entity({
                    position: Cesium.Cartesian3.fromDegrees(171.93274597, 42.41133712, 100000),
                    point: {show: true, color: Cesium.Color.RED, pixelSize: 0}
                })
                // customDataSource.entities.add(entities)
                // Math.cos()

                // 1度等于111公里
                const one_degree = 111 // km
                // 0.001度 等于 min_degree km
                const min_degree = one_degree / 1000
                // 100 海里 等于185.2公里
                const use_min_degree = 185.2 / min_degree / 1000


                const random_point = []
                for (let i = 0; i <= 360; i++) {
                    const new_lng = 171.93274597 + use_min_degree * Math.cos(i)
                    const new_lat = 42.41133712 + use_min_degree * Math.sin(i)
                    if (random_point.length < 2 && Math.random() > .5) {
                        random_point.push([new_lng, new_lat])
                    }


                    // const entities = new Cesium.Entity({
                    //     position: Cesium.Cartesian3.fromDegrees(new_lng, new_lat, 100000),
                    //     point: {show: true, color: Cesium.Color.RED, pixelSize: 1},
                    //     label: {
                    //         show: false,
                    //         text: i.toString()
                    //     }
                    // })
                    // customDataSource.entities.add(entities)
                }

                //   取矩形
                let index = -1
                for (let i of random_point) {
                    index++

                    let jiaodu = null
                    let rect_point = []
                    rect_point.push(i[0] + (37.04 / min_degree / 1000) * Math.cos(205))
                    rect_point.push(i[1] + (37.04 / min_degree / 1000) * Math.sin(205))
                    rect_point.push(i[0] + (37.04 / min_degree / 1000) * Math.cos(7))
                    rect_point.push(i[1] + (37.04 / min_degree / 1000) * Math.sin(7))


                    // 在矩形范围内随机取1点
                    const random_point = {}
                    random_point.lng = rect_point[2] + (rect_point[0] - rect_point[2]) * Math.random()
                    random_point.lat = rect_point[3] + (rect_point[1] - rect_point[3]) * Math.random()
                    // const random_point_entities = new Cesium.Entity({
                    //     position: Cesium.Cartesian3.fromDegrees(random_point.lng, random_point.lat, 100000),
                    //     point: {show: true, color: Cesium.Color.GREEN, pixelSize: 10}
                    // })


                    // customDataSource.entities.add(random_point_entities)
                    const LngLatArr = []
                    LngLatArr.push(random_point.lng, random_point.lat)
                    const property = new Cesium.SampledPositionProperty()
                    this.property_arr.push(property)
                    property.addSample(start_time, Cesium.Cartesian3.fromDegrees(random_point.lng, random_point.lat, 100000))


                    // 将最后一个位置保存起来
                    let last_point = []
                    // 假设开始时间为0, 每当走过一个凸包时间加1, 将时间保存在一个对象里面作为key
                    let current_use_time = 0
                    jiaodu = google.maps.geometry.spherical.computeHeading(new google.maps.LatLng(42.41133712, 171.93274597), new google.maps.LatLng(random_point.lat, random_point.lng)) + 180
                    this.jiaodu.push(jiaodu)
                    for (let i = 0; i < 52; i++) {
                        const end_point = this.destinationVincenty({
                            lng: LngLatArr[LngLatArr.length - 2],
                            lat: LngLatArr[LngLatArr.length - 1]
                        }, jiaodu, 7408)

                        // 将当前时间的位置保存到time_and_position中去
                        this.time_and_position[index][current_use_time] = end_point;
                        this.aircraft_flight[index].push(end_point)

                        last_point = end_point
                        this.end_points[index] = last_point
                        const current_time = Cesium.JulianDate.addSeconds(start_time, i * 30, new Cesium.JulianDate())
                        property.addSample(current_time, Cesium.Cartesian3.fromDegrees(end_point[0], end_point[1], 100000))
                        LngLatArr.push(...end_point)
                        current_use_time++
                    }


                    const orientation = new Cesium.VelocityOrientationProperty(property)
                    const entity = this.viewer.entities.add({
                        position: property,
                        availability: new Cesium.TimeIntervalCollection([new Cesium.TimeInterval({
                            start: start_time,
                            stop: stop_time
                        })]),
                        orientation: orientation,
                        model: {
                            show: true,
                            uri: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb",
                            minimumPixelSize: 64, //最小的模型像素
                            maximumScale: 528, //最大的模型像素
                            runAnimations: true, //是否显示动画
                            clampAnimations: true, //是否保持最后一针的动画
                            color: this.Cesium.Color.WHITE, //颜色
                        },
                        billboard: new this.Cesium.BillboardGraphics({
                            show: true,
                            image: 'https://cesium-1255710621.cos.ap-nanjing.myqcloud.com/cesium_info_bg.png',
                            scale: .27,
                            horizontalOrigin: 200,
                            verticalOrigin: -300

                        }),
                        label: {
                            show: true,
                            text: `CA${random_flight_number[index]}\n32000 -\n${jiaodu.toFixed(2)}°  480kn`,
                            font: 'font-size: 12px',
                            pixelOffset: new this.Cesium.Cartesian3(100, 0, 0)

                        },
                        path: {
                            show: true,
                            leadTime: 9999,
                            trailTime: 9999,
                            width: 1
                        }
                    })


                    const get_callback = (lng, lat, index) => {
                        const middle_index = Math.floor(LngLatArr.length / 4)
                        // tubao_entity.box.material = Cesium.Color.RED
                        return (time) => {

                            if (!this.is_show_tubao) {
                                return Cesium.Cartesian3(0, 0, 0)
                            }
                            const position = property.getValue(time)
                            if (!position) {
                                return new Cesium.Cartesian3(0, 0, 0)
                            }
                            const ellipsoid = this.viewer.scene.globe.ellipsoid;
                            const cartesian3 = new Cesium.Cartesian3(position.x, position.y, position.z);
                            const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
                            const _lat = Cesium.Math.toDegrees(cartographic.latitude);
                            const _lng = Cesium.Math.toDegrees(cartographic.longitude);

                            // 计算当前点 与终点的距离
                            const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat, lng), new google.maps.LatLng(last_point[1], last_point[0]))
                            // 判断飞机与终点的距离
                            const air_craft_distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(_lat, _lng), new google.maps.LatLng(last_point[1], last_point[0]))
                            // 如果飞机的距离 大于 当前点与终点的距离 显示
                            if (air_craft_distance > distance) {
                                if (middle_index > index) {
                                    // 在中心点之前
                                    const destance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat, lng), new google.maps.LatLng(_lat, _lng))
                                    // let size = destance / 10
                                    // let size = destance * index / 100
                                    let max_size = index * 1000
                                    let size = max_size  * (destance / 100000)
                                    if (size > max_size) size = max_size
                                    if (size < 100) size = 100;
                                    size /= 3
                                    return new Cesium.Cartesian3(size, size, size)
                                }else {
                                    // 在中心点之后

                                    const destance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(lat, lng), new google.maps.LatLng(_lat, _lng))
                                    // let size = destance / 10 * (Math.abs(middle_index - index)  / 10)
                                    let max_size = Math.abs(middle_index - Math.abs(middle_index - index)) * 1000;
                                    let size = max_size * (destance / 100000);
                                    if (size > max_size) size = max_size;
                                    if (size < 100) size = 100;
                                    size /= 3
                                    return new Cesium.Cartesian3(size, size, size)

                                }
                            }

                            // 如果飞机的距离 小于 当前点与终点的距离 不显示
                            if (air_craft_distance < distance) {
                                return new Cesium.Cartesian3(0, 0, 0)
                            }
                        }
                    }

                    // 设置凸包颜色回调
                    // const set_convex_hull_color_callback = (index) => {
                    //     const current_index = Math.abs(index - 1)
                    //     return () =>  {
                    //         return Cesium.Color.fromRandom({
                    //             minimumRed : 0.75,
                    //             minimumGreen : 0.75,
                    //             minimumBlue : 0.75,
                    //             alpha : .5
                    //         });
                    //     }
                    // }

                    for (let i = 0; i < LngLatArr.length / 2; i++) {
                        // 添加凸包
                        const tubao_entity = new Cesium.Entity({
                            position: Cesium.Cartesian3.fromDegrees(LngLatArr[i * 2], LngLatArr[i * 2 + 1], 100000),
                            orientation: orientation,
                            box: new Cesium.BoxGraphics({
                                dimensions: new Cesium.CallbackProperty(get_callback(LngLatArr[i * 2], LngLatArr[i * 2 + 1], i), false),
                                // material: new Cesium.ColorMaterialProperty(new Cesium.CallbackProperty(set_convex_hull_color_callback(index), false)),
                                material: Cesium.Color.WHITE.withAlpha(.5),

                                fill: true,
                            }),
                            meta: {
                                current_use_time: i,
                                lat: LngLatArr[i * 2],
                                lng: LngLatArr[i * 2 + 1],
                            }
                        })

                        this.fetch_convex_hull_points[index].push(tubao_entity)
                        customDataSource.entities.add(tubao_entity)
                    }

                    // 爆炸效果💥开始


                    // var emitterModelMatrix = new Cesium.Matrix4();
                    // var rotation = new Cesium.Quaternion();
                    // var hpr = new Cesium.HeadingPitchRoll();
                    // var trs = new Cesium.TranslationRotationScale();
                    //
                    // function computeEmitterModelMatrix() {
                    //     hpr = Cesium.HeadingPitchRoll.fromDegrees(0.0, 0.0, 0.0, hpr);
                    //     trs.translation = Cesium.Cartesian3.fromElements(
                    //         -14.0,
                    //         0.0,
                    //         1.4,
                    //         new Cesium.Cartesian3()
                    //     );
                    //     trs.rotation = Cesium.Quaternion.fromHeadingPitchRoll(hpr, rotation);
                    //
                    //     return Cesium.Matrix4.fromTranslationRotationScale(
                    //         trs,
                    //         emitterModelMatrix
                    //     );
                    // }
                    //
                    //
                    // var scene = this.viewer.scene;
                    // var particleSystem = scene.primitives.add(
                    //     new Cesium.ParticleSystem({
                    //         show: false,
                    //         image: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/baozha.png",
                    //         startColor: Cesium.Color.WHITE.withAlpha(1.0),
                    //         endColor: Cesium.Color.WHITE.withAlpha(0.0),
                    //
                    //         startScale: 1.0,
                    //         endScale: 2.0,
                    //
                    //         minimumParticleLife: 1.2,
                    //         maximumParticleLife: 1.2,
                    //         minimumSpeed: 1.0,
                    //         maximumSpeed: 4.0,
                    //
                    //         imageSize: new Cesium.Cartesian2(
                    //             25.0,
                    //             25.0
                    //         ),
                    //
                    //         emissionRate: 5.0,
                    //
                    //         bursts: [
                    //             // these burst will occasionally sync to create a multicolored effect
                    //             new Cesium.ParticleBurst({
                    //                 time: 5.0,
                    //                 minimum: 10,
                    //                 maximum: 100,
                    //             }),
                    //             new Cesium.ParticleBurst({
                    //                 time: 10.0,
                    //                 minimum: 50,
                    //                 maximum: 100,
                    //             }),
                    //             new Cesium.ParticleBurst({
                    //                 time: 15.0,
                    //                 minimum: 200,
                    //                 maximum: 100,
                    //             }),
                    //         ],
                    //         lifetime: 16.0,
                    //         emitter: new Cesium.CircleEmitter(2.0),
                    //         emitterModelMatrix: computeEmitterModelMatrix(),
                    //         updateCallback: applyGravity,
                    //     })
                    // );
                    //
                    // this.particleSystem_arr.push(particleSystem)
                    //
                    // var gravityScratch = new Cesium.Cartesian3();
                    //
                    // function applyGravity(p, dt) {
                    //     // We need to compute a local up vector for each particle in geocentric space.
                    //     var position = p.position;
                    //
                    //     Cesium.Cartesian3.normalize(position, gravityScratch);
                    //     Cesium.Cartesian3.multiplyByScalar(
                    //         gravityScratch,
                    //         0.0 * dt,
                    //         gravityScratch
                    //     );
                    //
                    //     p.velocity = Cesium.Cartesian3.add(
                    //         p.velocity,
                    //         gravityScratch,
                    //         p.velocity
                    //     );
                    // }
                    //
                    // this.entity_arr.push(entity)

                    // 设置飞机爆炸效果
                    // this.viewer.scene.preUpdate.addEventListener((scene, time) => {
                    //     if (this.distance < 50000 || this.flag) {
                    //         this.flag = true
                    //         for (let i in this.particleSystem_arr) {
                    //
                    //             const res = this.entity_arr[i].computeModelMatrix(time, new Cesium.Matrix4())
                    //             if (!res) {
                    //                 this.particleSystem_arr[i].show = false
                    //                 continue
                    //             }
                    //
                    //             this.particleSystem_arr[i].show = true
                    //             this.particleSystem_arr[i].modelMatrix = res;
                    //         }
                    //     }
                    // });

                    // 结束

                    // customDataSource.entities.add(new Cesium.Entity({
                    //     polyline: {
                    //         show: true,
                    //         positions: Cesium.Cartesian3.fromDegreesArray(LngLatArr),
                    //         material: Cesium.Color.RED
                    //     }
                    // }))

                    const entities = new Cesium.Entity({
                        rectangle: {
                            show: true,
                            coordinates: Cesium.Rectangle.fromDegrees(...rect_point),
                            material: Cesium.Color.BLUE.withAlpha(0)
                        }
                    })
                    customDataSource.entities.add(entities)
                }




                for (let i of this.fetch_convex_hull_points[0]) {
                    const current_time = i._meta.current_use_time;
                    const ellipsoid = this.viewer.scene.globe.ellipsoid;
                    const cartesian3 = new Cesium.Cartesian3(i.position._value.x, i.position._value.y, i.position._value.z);
                    const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
                    const _lat = Cesium.Math.toDegrees(cartographic.latitude);
                    const _lng = Cesium.Math.toDegrees(cartographic.longitude);

                    for (let ii of this.fetch_convex_hull_points[1]) {
                        if (ii._meta.current_use_time == current_time) {
                            // 记录冲突凸包
                            const ellipsoid = this.viewer.scene.globe.ellipsoid;
                            const cartesian3 = new Cesium.Cartesian3(ii.position._value.x, ii.position._value.y, ii.position._value.z);
                            const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
                            const lat = Cesium.Math.toDegrees(cartographic.latitude);
                            const lng = Cesium.Math.toDegrees(cartographic.longitude);
                            const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(_lat, _lng), new google.maps.LatLng(lat, lng))

                            if (distance < 25000) {
                                this.current_conflict[0].push(i)
                                this.current_conflict[1].push(ii)
                                i.box.material = Cesium.Color.RED.withAlpha(0.5)
                                ii.box.material = Cesium.Color.RED.withAlpha(0.5)
                            }
                            break
                        }
                        continue;
                    }
                }


                this.viewer.dataSources.add(customDataSource)

                this.viewer.flyTo(customDataSource)
            },


            destinationVincenty(lonlat, brng, dist) {
                var ct = {
                    a: 6378137,
                    b: 6356752.3142,
                    f: 1 / 298.257223563
                };
                var a = ct.a, b = ct.b, f = ct.f;

                var lon1 = lonlat.lng;
                var lat1 = lonlat.lat;

                var s = dist;
                var alpha1 = this.rad(brng);
                var sinAlpha1 = Math.sin(alpha1);
                var cosAlpha1 = Math.cos(alpha1);

                var tanU1 = (1 - f) * Math.tan(this.rad(lat1));
                var cosU1 = 1 / Math.sqrt((1 + tanU1 * tanU1)), sinU1 = tanU1 * cosU1;
                var sigma1 = Math.atan2(tanU1, cosAlpha1);
                var sinAlpha = cosU1 * sinAlpha1;
                var cosSqAlpha = 1 - sinAlpha * sinAlpha;
                var uSq = cosSqAlpha * (a * a - b * b) / (b * b);
                var A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
                var B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));

                var sigma = s / (b * A), sigmaP = 2 * Math.PI;
                while (Math.abs(sigma - sigmaP) > 1e-12) {
                    var cos2SigmaM = Math.cos(2 * sigma1 + sigma);
                    var sinSigma = Math.sin(sigma);
                    var cosSigma = Math.cos(sigma);
                    var deltaSigma = B * sinSigma * (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
                        B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
                    sigmaP = sigma;
                    sigma = s / (b * A) + deltaSigma;
                }

                var tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
                var lat2 = Math.atan2(sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
                    (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp));
                var lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1);
                var C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
                var L = lambda - (1 - C) * f * sinAlpha *
                    (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));

                var revAz = Math.atan2(sinAlpha, -tmp);  // final bearing
                return [lon1 + this.deg(L), this.deg(lat2)];
            },

            /**
             * 度换成弧度
             * @param  {Float} d  度
             * @return {[Float}   弧度
             */
            rad: function (d) {
                return d * Math.PI / 180.0;
            },

            /**
             * 弧度换成度
             * @param  {Float} x 弧度
             * @return {Float}   度
             */
            deg: function (x) {
                return x * 180 / Math.PI;
            },

        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            Cesium = this.Cesium
            // this.$nextTick(() => {
                // this.init()
            // })
        }

    }
</script>
<style>
    html,
    body {
        overflow: hidden;
        margin: 0px;
        padding: 0px;
    }

    #cesiumContainer {
        height: 100%;
        height: calc(100vh - 110px)
    }
</style>
